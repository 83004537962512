<template>
  <div class="el-main-weekly-detail" >
    <div style="margin-left: 30px;margin-right: 30px;width: 95%;height: 80%;">
      <div style="height: 40px"></div>
      <p style=" text-align: center;font-size: 22px;font-family: SourceHanSansCN-Medium, SourceHanSansCN;font-weight: 500;color: #000000;">{{punName}}</p>
      <p style=" margin-bottom: 30px; text-align: center;font-size: 18px;font-family: SourceHanSansCN-Medium, SourceHanSansCN;font-weight: 500;color: #000000;">({{fileNum}})</p>
      <div  style="text-align: -webkit-center;" v-html="contentHtml"></div>
      <div style="height: 40px"></div>
    </div>
  </div>

</template>

<script>
import { getPunishDetailApi} from "@/api/weeklyApi";

export default {
  data() {
    return {
      activeName: "first",
      contentHtml: "",
      punName: "",
      fileNum: ""
    };
  },
  mounted() {

    this.getDetail()
  },
  methods: {
    getDetail(){
      getPunishDetailApi({
        params: {
          punishId: this.$route.query.id,
          key: this.$route.query.key,
          date: this.$route.query.date
        },
      }).then((ret) => {
        console.info(ret.data.data)
        this.contentHtml = ret.data.data.content;
        this.punName = ret.data.data.name;
        this.fileNum = ret.data.data.fileNum;
      });
    }
  },
};
</script>

<style lang="scss" >
.MsoNormalTable{
  width: 80% !important;
  margin-bottom: 20px !important;
}
.el-main-weekly-detail {
  width: 100%!important;
  height: 100%!important;
  background:  #FFFFFF 100% !important;
}
</style>