<template>
  <div class="el-main-weekly" >
    <div  style="display:flex;justify-content: center;" >
      <div  class="weekly-bank-background">
        <!--头部模块-->
        <div>
          <img style="position: absolute;left: -15px;top: 21px;width: 180px;height: 32px" src="@/assets/image/weekly/bank/logo.svg" alt=""/>
          <div class="weekly-div-background-date">
            <div style="height: 15px"></div>
            <div>
              <span  class="weekly-punish-date">{{year}}/{{weeklyInfo.beginDate}}-{{weeklyInfo.endDate}}</span>
            </div>
          </div>
          <img  style="position: absolute;left: 8px;top: 73px;width: 199px;height: 132px" src="@/assets/image/weekly/bank/wenan.png" alt=""/>
          <img  style="position: absolute;left: 210px;top: 68px;width: 162px;height: 183px" src="@/assets/image/weekly/bank/chv2.png" alt=""/>
        </div>


        <img v-if="this.weeklyDate == 20240826"  style="position: absolute;left: 7px;top: 253px;width: 359px;height: 198px" src="@/assets/image/weekly/bank/jtzmv2.png" alt=""/>

        <!--本周处罚详情-->
        <div v-show="!clickFlag">

          <img v-if="this.weeklyDate == 20240826" style="position: absolute;z-index:-999;left: 22px;top: 513px;width: 330px;height: 88px" src="@/assets/image/weekly/bank/di.png" alt=""/>
          <img v-else style="position: absolute;z-index:-999;left: 22px;top: 313px;width: 330px;height: 88px" src="@/assets/image/weekly/bank/di.png" alt=""/>
          <div>
            <span  :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:270px'" @click="filtrate(true)"  class="weekly-tag-right-font">{{year}}年处罚分析</span>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" class="weekly-tag-right-font1">本周处罚详情</span>
            <img v-if="this.weeklyDate == 20240826" style="position: absolute;left: 12px;top: 453px;width: 353px;height: 55px" src="@/assets/image/weekly/bank/right-tab-s.png" alt=""/>
            <img v-else style="position: absolute;left: 10px;top: 254px;width: 353px;height: 55px" src="@/assets/image/weekly/bank/right-tab-s.png" alt=""/>
          </div>
          <div :style="this.weeklyDate == 20240826 ? 'margin-top:455px' : 'margin-top:255px'"  class="weekly-tag-background-x">
            <div>

              <div style="height: 20px"></div>
              <div style="width: 331px;margin-left: 10.5px;border-radius: 10px;">

                <div class="weekly-table-bankgroud">
                  <div style="height: 10px"></div>
                  <div style="text-align: center" >
                    <img style="margin-right: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                    <span class="weekly-font-title"> 本周罚单数 </span>
                    <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                  </div>

                  <div style="width: 300px;margin-left: 17px;margin-top: 0px;">
                    <span class="weekly-font-table">本周（{{weeklyInfo.tableDetailRespVO.beginDate}}-{{weeklyInfo.tableDetailRespVO.endDate}}），
                      金监局及其派出机构对全国各地区股份制商业银行开出的罚单数如下：</span>
                  </div>
                  <div style="margin-bottom: 5px;margin-left: 15px">
                    <span class="weekly-font-table-beizhu">列表中未展示的地区本周无罚单</span>
                  </div>

                  <div class="regional-table">
                    <div class="regional-table-bankground">
                      <el-table
                              :data="weeklyInfo.tableList"
                              border
                              :row-style="{height: 30+'px',background: 'transparent !important'}"
                              :cell-style="{padding:0+'px','text-align':'center',background: 'transparent !important','font-size': '14px','font-family': '思源黑体','font-weight': '400',color: '#3B3B4C','border-color':'#94BAFF'}"
                              :header-cell-style="{height: 30+'px',padding:0+'px',background:'#478BFD','text-align':'center','font-size': '14px','font-family': '思源黑体','font-weight': '700',color: '#FFFFFF','border-color':'#94BAFF'}"
                              class="weekly-el-table-st"
                      >
                        <el-table-column prop="subName" label="机构" width="90">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="number" label="数量" width="60">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName,scope.row.number)" :style="scope.row.number == 0 ? {color: '#2C6DFF 100%','font-weight': '700'}:{ 'text-decoration':'underline','font-weight': '700',color: '#2C6DFF',cursor: 'pointer'}">{{ scope.row.number }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="subName1"  label="机构" width="90">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {}:{cursor: 'pointer'}">{{ scope.row.subName1 }}</div>
                          </template>
                        </el-table-column>
                        <el-table-column prop="number1"  label="数量" width="60">
                          <template slot-scope="scope">
                            <div @click="goAnchor('#' + scope.row.subName1,scope.row.number1)" :style="scope.row.number1 == 0 ? {color: '#2C6DFF 100%','font-weight': '700'}:{'text-decoration':'underline',color: '#2C6DFF','font-weight': '700',cursor: 'pointer'}">{{ scope.row.number1 }}</div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                  <div style="margin-top: 10px;margin-left: 15px">
                    <span class="weekly-font-table-beizhu">点击蓝色数字查看该地区处罚清单</span>
                  </div>


                  <!--*********************人民银行模块**********************-->
                  <div style="height: 10px"></div>
                </div>
              </div>


            </div>
            <div v-for="item,index in punishList" :id="item.subName" >
              <div  class="weekly-div-background-s" >
                <div style="text-align: center;">
                  <div style="height: 7px"></div>
                  <span class="weekly-font-other-title"> {{item.subName}} </span>
                </div>
              </div>
              <div style="width: 329px;background: linear-gradient(rgba(44, 109, 255, 0.47),rgba(44, 109, 255, 0.09));margin-left: 11px;border-radius: 0px 0px 20px 20px;">
                <div  class="weekly-div-background-z" >
                  <div v-if="index == 0 " style="padding-top: 10px;margin-left: 15px;margin-bottom: -10px">
                    <span class="weekly-font-table-beizhu">点击处罚信息，查看罚单详情</span>
                  </div>
                  <div style="height: 20px;"></div>
                  <div>
                    <div>
                      <div v-if="item.detailList != null">
                        <div v-for="item1 in item.detailList" @click="punDetail(item1.punishId)" style="margin-bottom: 20px;display: flex;">
                          <div style="margin-left: 15px">
                            <img src="@/assets/image/weekly/bank/yuan-zi.svg" style="margin-top: 5px;width: 15px; flex: 0 0 11.5px; height: 15px" alt=""/>
                          </div>
                          <div style="width: 284px;font-size: 16px;font-weight: 400;line-height: 24px;font-family: 思源黑体;margin-left: 2.5px;cursor: pointer;" v-html="item1.detailName" >
                          </div>

                        </div>
                      </div>

                    </div>

                    <div v-if="index != punishList.length - 1 " style="height: 5px"></div>
                    <div v-if="index == punishList.length - 1 && !otherFlag" style="height: 5px"></div>
                    <!--<div v-if="index == punishList.length - 1 && otherFlag" style="text-align: center;height: 40px;cursor: pointer;" @click="moreOther">-->
                      <!--<span class="weekly-font-more">加载更多</span>-->
                    <!--</div>-->
                  </div>
                </div>
                <div style="height: 1px"></div>
              </div>

            </div>

            <!--人行模块-->

          </div>
          <div style="height: 20px"></div>
          <div style="width: 351px;height: 15px;margin-left: 12px;margin-top: -36px;border-radius: 0px 0px 10px 10px"></div>
        </div>
        <!--年度处罚分析-->
        <div v-show="clickFlag">
          <div>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:265px'" class="weekly-tag-left-font1">{{year}}年处罚分析</span>
            <span :style="this.weeklyDate == 20240826 ? 'top:465px' : 'top:270px'" @click="filtrate(false)"  class="weekly-tag-left-font">本周处罚详情</span>
            <img v-if="this.weeklyDate == 20240826" style="position: absolute;left: 11px;top: 453px;width: 353px;height: 55px" src="@/assets/image/weekly/bank/left-tab-s.png" alt=""/>
            <img  v-else style="position: absolute;left: 11px;top: 253px;width: 353px;height: 55px" src="@/assets/image/weekly/bank/left-tab-s.png" alt=""/>
          </div>
          <div :style="this.weeklyDate == 20240826 ? 'margin-top:455px' : 'margin-top:255px'" class="weekly-tag-background-x">


            <div v-for="item,index in weeklyInfo.reportList">
              <div style="height: 10px"></div>
              <div v-if="index != 0" style="height: 20px"></div>
              <div style="margin-left: 0px" class="weekly-punish-div-fxbg">
                <div style="height: 10px"></div>
                <span class="weekly-punish-title"> {{ item.orgName }}处罚分析</span>
              </div>

              <div style="border-radius: 8px;width: 323px;margin-left: 14px;margin-bottom: 20px;background: rgb(227, 242, 255,0.4);">
                <div style="margin-left: -20px">
                  <!--总体处罚情况-->
                  <div style="margin-left: 15px">
                    <div style="text-align: center">
                      <img style="margin-right: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                      <span class="weekly-font-title"> 总体处罚情况 </span>
                      <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                    </div>
                    <div style="width: 300px;margin-left: 20px">
                      <span class="weekly-font-ensemble">{{year}}年截至{{weeklyInfo.month}}月{{weeklyInfo.day}}日，总局共向股份制商业银行开出罚单<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.sumPun}}</span>张，其中机构罚单<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.comPun}}</span>张，个人罚单<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.perPun}}</span>张；</span>
                      <span class="weekly-font-ensemble">处罚金额<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.sumMoney}}</span>万元，机构处罚金额<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.comMoney}}</span>万元，个人处罚金额<span style="color: #2C6DFF;font-weight: 700">{{item.rsxReport.perMoney}}</span>万元。</span>
                    </div>
                  </div>
                  <div class="weekly-punish-div-background-z"></div>
                  <!--被罚人员分析-->
                  <div>
                    <div style="text-align: center">
                      <img style="margin-right: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                      <span class="weekly-font-title"> 被罚人员分析 </span>
                      <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                    </div>
                    <div style="margin-left: 15px">
                      <div>
                        <div style="width: 310px;margin-left: 20px">
                          <span class="weekly-font-ensemble" style="font-weight: 700;color: #3B3B4C">共<span style="color: #2C6DFF">{{item.rsxReport.perPunNum}}</span>张罚单涉及或关联到个人处罚。</span>
                        </div>
                        <div style="width: 310px;margin-left: 20px;margin-top: 8px;display: flex;align-items: center">
                          <img src="@/assets/image/weekly/bank/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                          <span class="weekly-font-person">高管层{{item.rsxReport.gzPun}}人，罚款{{item.rsxReport.gzMoney}}万元；</span>
                        </div>
                        <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                          <img src="@/assets/image/weekly/bank/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                          <span class="weekly-font-person">部门级别{{item.rsxReport.bmPun}}人，罚款{{item.rsxReport.bmMoney}}万元；</span>
                        </div>
                        <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                          <img src="@/assets/image/weekly/bank/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                          <span class="weekly-font-person">经办人员{{item.rsxReport.jbryPun}}人，罚款 {{item.rsxReport.jbryMoney}}万元。</span>
                        </div>
                        <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                          <img src="@/assets/image/weekly/bank/xing.png" style="margin-left: -2px;width: 18px;height: 18px" alt=""/>
                          <span class="weekly-font-person">其他人员{{item.rsxReport.qtPun}}人，罚款 {{item.rsxReport.qtMoney}}万元。</span>
                        </div>
                        <div style="width: 310px;margin-left: 20px;display: flex;align-items: center;margin-top: 4px;">
                          <span class="weekly-font-person-beizhu">( 其他人员: 指其他职务或者未披露职务的人员)</span>
                        </div>
                      </div>
                      <div style="display: flex; margin-top: 20px;align-items: center">
                        <el-progress :show-text = "true" :format="setItemText(1,item.rsxReport.gzPun)" :text-inside="true" :stroke-width="16" color="rgb(44, 109, 255,0.8)" style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="item.rsxProgressVO.ggPerson"></el-progress>
                        <div class="weekly-punish-div-person">
                          <div style="height: 2px"></div>
                          <span class="weekly-punish-div-person-font">高管层 </span>
                        </div>
                        <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText(2,item.rsxReport.gzMoney)"  :text-inside="true" color="#F15872" :stroke-width="16"  style="width: 100px;" :percentage="item.rsxProgressVO.ggMoney"></el-progress>
                      </div>
                      <div style="display: flex;align-items: center;margin-top: 10px;">
                        <el-progress :show-text = "true" :text-inside="true" :format="setItemText(1,item.rsxReport.bmPun)"  :stroke-width="16" color="rgb(44, 109, 255,0.8)"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="item.rsxProgressVO.bmPerson"></el-progress>
                        <div class="weekly-punish-div-person">
                          <div style="height: 2px"></div>
                          <span class="weekly-punish-div-person-font">部门级别 </span>
                        </div>
                        <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText(2,item.rsxReport.bmMoney)"  :text-inside="true" color="#F15872"   :stroke-width="16"  style="width: 100px;" :percentage="item.rsxProgressVO.bmMoney"></el-progress>
                      </div>
                      <div style="display: flex;align-items: center;margin-top: 10px;">
                        <el-progress :show-text = "true" :text-inside="true" :format="setItemText(1,item.rsxReport.jbryPun)"   :stroke-width="16" color="rgb(44, 109, 255,0.8)"  style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="item.rsxProgressVO.jbPerson"></el-progress>
                        <div class="weekly-punish-div-person">
                          <div style="height: 2px"></div>
                          <span class="weekly-punish-div-person-font">经办人员 </span>
                        </div>
                        <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText(2,item.rsxReport.jbryMoney)"  :text-inside="true" color="#F15872"  :stroke-width="16"  style="width: 100px;" :percentage="item.rsxProgressVO.jbMoney"></el-progress>
                      </div>
                      <div style="display: flex;align-items: center;margin-top: 10px;">
                        <el-progress :show-text = "true" :text-inside="true"  :format="setItemText(1,item.rsxReport.qtPun)"  :stroke-width="16" color="rgb(44, 109, 255,0.8)"   style="width: 100px;transform: rotate(180deg);margin-left: 20px;" :percentage="item.rsxProgressVO.qtPerson"></el-progress>
                        <div class="weekly-punish-div-person">
                          <div style="height: 2px"></div>
                          <span class="weekly-punish-div-person-font">其他 </span>
                        </div>
                        <el-progress class="el-progress-bar__innerText1" :show-text = "true" :format="setItemText(2,item.rsxReport.qtMoney)"  :text-inside="true" color="#F15872"  :stroke-width="16"  style="width: 100px;" :percentage="item.rsxProgressVO.qtMoney"></el-progress>
                      </div>
                    </div>

                  </div>
                  <div class="weekly-punish-div-background-z"></div>
                  <!--处罚机构分析-->
                  <div>
                    <div style="text-align: center">
                      <img style="margin-right: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                      <span class="weekly-font-title"> 处罚机构分析 </span>
                      <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                    </div>


                    <div style="width: 310px;margin-left: 30px">
                      <span class="weekly-font-ensemble">{{item.rsxSubVO.maxMoneyName}}处罚金额最高，累计高达<span style="font-weight: 700;color: #2C6DFF">{{item.rsxSubVO.maxMoney}}</span>万；{{item.rsxSubVO.maxPunName}}罚单数量最多，累计罚单<span style="font-weight: 700;color: #2C6DFF">{{item.rsxSubVO.maxPunNumber}}</span>张。</span>
                    </div>

                    <div :id="'punishjg' + index" style="margin-left: 32px;height: 230px;width: 300px;margin-top: 20px;margin-bottom: -30px"></div>
                  </div>
                  <div style="margin-left: 11px" class="weekly-punish-div-background-x"></div>
                  <!--处罚事由排行-->
                  <div>
                    <div style="text-align: center">
                      <img style="margin-right: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                      <span class="weekly-font-title"> 处罚事由排行 </span>
                      <img style="transform:rotatey(180deg);margin-left: 20px;" src="@/assets/image/weekly/bank/biaoti.svg" alt=""/>
                    </div>
                    <div style="width: 310px;margin-left: 30px">
                   <span class="weekly-font-ensemble">
                      股份制商业银行处罚频次较高的事由包括：
                      {{item.rsxReasonVO.nameList[item.rsxReasonVO.nameList.length-1]}}共处罚<span style="font-weight: 700;color: #2C6DFF">{{ item.rsxReasonVO.reasonNumList.length == 0? 0:  item.rsxReasonVO.reasonNumList[item.rsxReasonVO.reasonNumList.length - 1]}}</span>次；
                      {{item.rsxReasonVO.nameList[item.rsxReasonVO.nameList.length-2]}}共处罚<span style="font-weight: 700;color: #2C6DFF">{{item.rsxReasonVO.reasonNumList.length < 2? 0:  item.rsxReasonVO.reasonNumList[item.rsxReasonVO.reasonNumList.length - 2]}}</span>次；
                      {{item.rsxReasonVO.nameList[item.rsxReasonVO.nameList.length-3]}}共处罚<span style="font-weight: 700;color: #2C6DFF">{{item.rsxReasonVO.reasonNumList.length < 3? 0:item.rsxReasonVO.reasonNumList[item.rsxReasonVO.reasonNumList.length - 3]}}</span>次。
                   </span>
                    </div>
                    <div :id="'reason' + index" :style="{'height': item.rsxReasonVO.maxHeight + 'px','width': '330px','margin-top': '-30px','margin-left': '12px','margin-bottom':'-20px'}"></div>
                  </div>

                </div>

              </div>

            </div>

          </div>
          <div style="width: 351px;height: 15px;margin-left: 12px;margin-top: -20px;border-radius: 0px 0px 10px 10px"></div>

        </div>
        <div style="height: 25px"></div>
        <span  style="position: absolute;bottom: 10px;z-index:-998;left:72px;color: #808080;font-size: 14px">注: 统计数据期间以监管披露时间为准</span>
      </div>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts';
  import { getWeeklyDetailApi,getYearDetailApi,getWeekDetailApi} from "@/api/weeklyBankApi";
  export default {
    name: 'report',
    data() {
      return {
        clickFlag:false,
        otherFlag:false,
        weeklyDate: undefined,
        showPopover:false,
        year: "",
        groupId: "",
        weeklyInfo: {
          rsxReport: {},
          tableDetailRespVO : {},
          config: {},
          rsxSubVO: {
            nameList: [],
            numberList: [],
            moneyList: [],
          },
          rsxReasonVO: {
            nameList: [],
            reasonNumList: [],
            maxLenList: [],
          },
          rsxProgressVO: {},
        },
        punishList: [],
      };
    },
    mounted() {
      // var date = this.$route.query.date;
      // if(date < 20240826){
      //   this.$router.push({ path: "/weekly/dj/report/v1", query: this.$route.query });
      // }
      // this.groupId = this.genID()
      this.initWeeklyDatas();
    },
    methods: {
      genID(){
        return Number(Math.random().toString().substr(3,3) + Date.now()).toString();
      },
      //锚点跳转
      goAnchor(selector,value) {/*参数selector是id选择器（#anchor14）*/
        if(value != 0){
          document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
          });
        }
      },
      setItemText(type,value) {

        if(type == 1){
          return () => {
            return value + '人'
          }
        }
        if(type == 2){
          return () => {
            return value + '万元'
          }
        }
      },
      initWeeklyDatas(){
        var key = this.$route.query.key;
        var date = this.$route.query.date;
        var userId = this.$route.query.UserId;
        this.weeklyDate = date;
        var dataStatus = this.$route.query.dataStatus;
        getWeeklyDetailApi({
          params: {
            key: key,
            date: date,
            userId: userId,
            groupId: this.groupId,
            dataStatus: dataStatus,
          },
        }).then((ret) => {
          //处理下划线以及字体颜色
          ret.data.data.detailRespList.forEach(item =>{
            if(item.detailList != null){
              item.detailList.forEach(item1 =>{
                item1.detailName = item1.detailName + "<span style=\"\">";
                item1.detailName = item1.detailName.replaceAll("@*","")
                item1.detailName = item1.detailName.replaceAll("【","")
                item1.detailName = item1.detailName.replaceAll("】","")
                item1.detailName = item1.detailName.replaceAll("*@","")
                item1.detailName = item1.detailName.replaceAll("@#","<span style=\"color: #2C6DFF;font-weight: 700\">")
                item1.detailName = item1.detailName.replaceAll("#@","</span>")
                item1.detailName = item1.detailName + "</span>"
                item1.detailName = item1.detailName.replaceAll("(","<div><span style=\"font-size: 14px;font-style: italic;font-family: PingFang-SC-Medium;font-weight: 300;color: #666666\">注：")
                item1.detailName = item1.detailName.replaceAll(")","</span></div>")
                item1.detailName = item1.detailName +"<div style=\" width: 5px;height: 13px;margin-right: 3px;display: inline-block \"><div style=\"position: relative\"><i class=\"el-icon-d-arrow-right\" style=\"font-size: 13px;font-weight: 700;color: #2C6DFF;position: absolute;top: 1px;right: -5px\"></i> </div></div>"
              })
            }
          })
          this.punishList = ret.data.data.detailRespList;
          this.weeklyInfo =  ret.data.data;
          console.info("this.weeklyInfo")
          console.info(this.weeklyInfo)
          this.year = this.weeklyInfo.weekly.year;
          this.weeklyInfo.reportList.forEach((value,index)=>{
            //画图表
            this.$nextTick(() => {
              this.getEcharsReasonRsx(index); //人身险处罚事由
              this.getEcharsRsx(index); //人身险处罚机构
            });
          })
        });
      },
      // tag点击事件
      filtrate(value) {
        this.clickFlag = value
      },
      // 加载更多点击事件
      moreOther() {
        this.otherFlag = false;
      },
      punDetail(id) {
        let text = this.$router.resolve({ name: "weeklyBankDetail", query: {
            id : id ,
            key:this.$route.query.key,
            date:this.$route.query.date
          } });
        window.open(text.href, '_blank')
      },
      getEcharsRsx(index){
        var myChart = echarts.init(document.getElementById('punishjg' + index));
        const option = {
          legend: {

            x:'right',      //可设定图例在左、右、居中
            padding:[0,60,0,0],
            data: ['罚单(张)',  '罚金（万元）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.weeklyInfo.reportList[index].rsxSubVO.nameList,
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                formatter: function (value) {
                  return value.split("").join("\n")
                }
              }
            }
          ],
          yAxis: [
            {
              id: '罚单(张)',
              name: '(张)',
              nameTextStyle:{
                padding:[0, 0, 0, -30],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(243, 231, 218)"
                }
              },
              min: 0,
              max: this.weeklyInfo.reportList[index].rsxSubVO.numberMax,
              interval: this.weeklyInfo.reportList[index].rsxSubVO.numberMax/5,
            },
            {
              id: '罚金（万元）',
              name: '万元',
              nameTextStyle:{
                padding:[0, 0, 0, 35],
              },
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: "rgb(231, 236, 247)"
                }
              },
              min: 0,
              max: this.weeklyInfo.reportList[index].rsxSubVO.moneyMax,
              interval: this.weeklyInfo.reportList[index].rsxSubVO.moneyMax/5,
            }
          ],
          dataZoom: [
            {
              type: "inside",  // 支持内部鼠标滚动平移
              start: 0,
              end: (20/this.weeklyInfo.reportList[index].rsxSubVO.nameList.length)*100,
              zoomOnMouseWheel: false,  // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
            }
          ],
          series: [
            {
              id: '罚单(张)',
              name: '罚单(张)',
              type: 'line',
              itemStyle: {
                normal: {
                  color: '#C6281C'
                }
              },
              symbol: 'circle',
              lineStyle: {
                width: 1,
                type: 'solid' //'dotted'虚线 'solid'实线
              },
              data: this.weeklyInfo.reportList[index].rsxSubVO.numberList
            },

            {
              id: '罚金（万元）',
              name: '罚金（万元）',
              type: 'bar',
              barWidth: '60%',
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgb(41, 106, 255,0.3)'
                  }, {
                    offset: 1,
                    color: 'rgb(41, 106, 255)'
                  }]),
                }
              },

              yAxisIndex: 1,
              data: this.weeklyInfo.reportList[index].rsxSubVO.moneyList
            }
          ]
        };
        option && myChart.setOption(option);
      },
      getEcharsReasonRsx(index){
        var myChart = echarts.init(document.getElementById('reason' + index));
        const  option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value',
              axisLabel: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              splitLine: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              axisLine: {
                show: false
              },
              data: this.weeklyInfo.reportList[index].rsxReasonVO.nameList,
              axisLabel: {
                textStyle: {
                  fontSize: 12
                },
                formatter: (val) => {
                  let c = document.createElement("canvas");
                  const ctx = c.getContext("2d");
                  ctx.font = "16px normal"; // 设置画布内的字体，与设置的textStyle一致
                  const arr = val.split("");
                  arr.map((item) => ctx.measureText(item).width)
                          .reduce((pre, next, index) => {
                            const nLen = pre + next;
                            if (nLen > 180) {
                              arr[index - 1] += "\n";
                              return next;
                            } else {
                              return nLen;
                            }
                          });
                  c = null;
                  return arr.join("");
                }
              }
            }
          ],
          series: [
            {
              type: 'bar',
              label: {
                show: true,
                position: 'right'
              },

              barWidth: '60%',
              itemStyle: {
                normal: {
                  //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                  barBorderRadius: [0, 30, 30, 0],
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 0,
                    color: 'rgb(41, 106, 255,0.3)'
                  }, {
                    offset: 1,
                    color: 'rgb(41, 106, 255)'
                  }]),
                }
              },

              emphasis: {
                focus: 'series'
              },
              data: this.weeklyInfo.reportList[index].rsxReasonVO.reasonNumList
            },
            {
              type: 'bar',
              itemStyle: {
                normal: { color: 'rgba(0, 0, 0, 0.05)' }
              },
              barWidth: '60%',
              barGap: '-100%',
              data: this.weeklyInfo.reportList[index].rsxReasonVO.maxLenList,
              animation: false
            }
          ]
        };
        option && myChart.setOption(option);
      },
    },
  };
</script>

<style lang="scss" >
  .weekly-bank-background {
    width: 375px;
    min-height: 580.15px;
    background: url("../../assets/image/weekly/bank/background.png") no-repeat  top 0px left 0px,#E3F2FF;

    background-size: auto ;
    position: absolute;
    z-index: 999;
    overflow: hidden;
    .weekly-tags-left {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 15px;
      top: 272px;
      background: linear-gradient(-70deg,#30DDF7  0%, #008AFF  100%);
      border-radius: 430px 0px 0px 430px;
    }
    .weekly-tags-right {
      width: 172.5px;
      height: 43.2px;
      position: absolute;
      left: 186.5px;
      top: 272px;
      background: linear-gradient(-70deg, #30DDF7  0%, #008AFF 100%);
      border-radius: 0px 430px 430px 0px;
    }

    .weekly-tag-left-font{
      position: absolute;
      width: 108px;
      height: 27px;
      left: 36px;
      top: 415px;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;
      cursor: pointer;
      z-index: 999;
    }

    .weekly-tag-right-font{
      position: absolute;
      width: 135px;
      height: 27px;
      left: 220px;
      top: 415px;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0px;
      cursor: pointer;
      z-index: 999;
    }

    .weekly-tag-left-font1{
      position: absolute;
      width: 165px;
      height: 27px;
      left: 200px;
      top: 415px;
      background: rgb(44, 109, 255);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 900;
      line-height: 30px;
      letter-spacing: 0px;
      z-index: 999;
    }

    .weekly-tag-right-font1{
      position: absolute;
      width: 132px;
      height: 33px;
      left: 40px;
      right: 179px;
      top: 415px;
      background: rgb(44, 109, 255);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 900;
      line-height: 30px;
      letter-spacing: 0px;
      z-index: 999;
    }

    .weekly-table-bankgroud {
      background: url("../../assets/image/weekly/bank/table-bk.png") no-repeat  top 0px left 0px;
      background-size: 100% 100%;
      width: 329px;
      margin-left: 1px;
      margin-bottom: 0px;
      min-height: 185px;
      border-radius: 10px;
    }

    .weekly-tag-background-x {
      background-image: url("../../assets/image/weekly/bank/tab-left-x.png");
      margin-left: 12px;
      margin-top: 385px;
      width: 351px;
      min-height: 500px;
      background-size: 100% 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-tag-right-background-x {
      background-image: url("../../assets/image/weekly/bank/tab-x.png");
      margin-left: 10px;
      margin-top: 385px;
      width: 361px;
      min-height: 500px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .weekly-div-background-s {
      background-image: url("../../assets/image/weekly/bank/yj-s.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 329px;
      margin-left: 11px;
      margin-top: 15px;
      min-height: 40px;
    }
    .weekly-div-background-z {
      background: rgb(243, 249, 255);

      border-radius: 0px 0px 20px 20px;
      width: 327px;
      margin-left: 1px;
      min-height: 50px;
    }
    .weekly-font-title {
      width: 58.5px;
      height: 18.5px;
      color: #000000;
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      text-align: justify;
      line-height: 50px;
    }
    .weekly-font-other-title {
      width: 58.5px;
      height: 18.5px;
      color: rgb(255, 255, 255);
      font-family: 思源黑体;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
    }
    .weekly-punish-title {
      width: 242px;
      height: 21.5px;
      margin-left: 25px;
      color: rgb(59, 59, 76);
      font-family: 思源黑体;
      font-size: 17px;
      font-weight: 700;
      line-height: 18px;
    }
    .weekly-punish-div-background-z {
      background-image: url("../../assets/image/weekly/bank/line.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 340px;
      margin-left: 12px;
      margin-top: 10px;
      min-height: 51px;
    }

    .weekly-punish-div-background-x {
      background-image: url("../../assets/image/weekly/bank/line.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 340px;
      margin-left: 8px;
      min-height: 51px;
    }

    .weekly-div-background-date {
      background-image: url("../../assets/image/weekly/bank/date.png");
      margin-left: 258px;
      margin-top:14px;
      width: 117px;
      height: 39px;
      text-align: center;
    }

    .weekly-punish-date {
      color: rgb(255, 245, 225);
      font-family: 思源黑体;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      padding-left: 5px;
    }
    .weekly-font-ensemble {
      width: 300px;
      height: 111.5px;
      font-family: 思源黑体;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    .weekly-font-table {
      width: 280px;
      height: 111.5px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: 400;
      line-height: 20px;
    }
    .weekly-font-person {
      color: rgb(59, 59, 76);
      font-family: 思源黑体;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      width: 250px;
      height: 22.5px;
    }
    .weekly-font-more {
      width: 52px;
      height: 12px;
      font-size: 13px;
      font-family: 思源黑体;
      font-weight: 400;
      color: #1472FF;
    }
    .weekly-punish-div-fxbg {
      background-image: url("../../assets/image/weekly/bank/fxbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 353px;
      height: 40px;
      margin-left: 10px;
      margin-bottom: 15px;
    }
    .weekly-punish-div-person {
      background: linear-gradient(90.00deg, rgb(155, 201, 255),rgb(71, 133, 249) 100%);
      box-shadow: 0px 0px 8px 0px rgba(16, 67, 183, 0.3);
      border-radius: 4px;
      text-align: center;
      width: 90px;
      height: 30px;
    }
    .weekly-punish-div-person-font {
      width: auto;
      height: 13.5px;
      font-size: 14px;
      font-family: 思源黑体;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 26px;

    }
    .weekly-font-person-beizhu {
      font-size: 13px;
      font-family: 思源黑体;
      font-weight: 300;
      color: #666666;
    }

    .weekly-font-table-beizhu {
      font-size: 12px;
      font-family: 思源黑体;
      font-weight: 300;
      color: #666666;
    }

    .weekly-el-table-st {
      width: 321px;
      border-radius: 5px;
      border-color: #94BAFF;
      background: transparent !important;
    }
    .regional-table{
      width: 301px;
      margin-left: 14px;
      border-radius: 5px;

    }
    .regional-table-bankground{
      background-size: 100% 100%;
      width: 301px;
    }
  }

  .el-main-weekly {
    width: 100%!important;
    height: 100%!important;
    background: #ffffff !important;
  }
  // 进度条样式
  .el-progress-bar__outer,
  .el-progress-bar__inner {
    border-radius: inherit;
    background-color: rgba(214, 187, 138, 0.18);
  }
  .el-table--border {
    border-bottom: 1px solid #EBEEF5;
  }
  .el-progress-bar__innerText {
    color: #433F5E;
    font-size: 11px;
    margin: 0 3px;
    transform: rotate(180deg);
  }

  .el-table--border::after, .el-table--group::after{
    width: 0;
  }
  .custom-popover {
    background-color: white!important; /* 背景色 */
    color: #98783D; /* 文字颜色 */
    border-color: #ddd; /* 边框颜色 */
    border-radius: 20px;
    /* 其他需要修改的样式 */
    .popper__arrow {
      font-size: 204px; /* 调整三角形大小 */
    }
  }
  .el-progress-bar__innerText1 {
    .el-progress-bar__innerText{
      color: #3B3B4C;
      font-size: 11px;
      margin: 0 3px;
      transform: rotate(0deg);
    }
  }

</style>
